$color-black: #19161D;
$color-bg: #F7F3ED;
$color-modal-bg: #19161d40;

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

html {
    font-size: 10px;

    @media only screen and (max-width: 759px) {
        & {
            font-size: 8px;
        }
    }
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: $color-black;
    background-color: $color-bg;
}

.main-container {
    padding: 0 10rem;
    // position: relative;

    @media only screen and (max-width: 739px) {
        & {
            padding: 0 6rem;
        }
    }

    @media only screen and (max-width: 519px) {
        & {
            padding: 0 4rem;
        }
    }
    
}

.section {
    padding: 4rem 0;
}

@keyframes slidebg {
    to {
        background-position: calc(100% - 23.2rem);
    }
}

@keyframes slidebg2 {
    to {
        background-position: calc(60vh - 34.8rem);
    }
}

@keyframes modalSlideUp {
    from {
        top: 20rem;
        opacity: 0;
    }

    to {
        top: 6rem;
        opacity: 1;
    }
}

@keyframes contentSlideUp {
    from {
        transform: translateY(2rem);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes modalHide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes modalDisplay {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.jd-btn-primary {
    &,
    &:link,
    &:visited {
        display: inline-block;
        text-decoration: none;
        position: relative;
        padding: 1.2rem 4rem;
        color: $color-black;
        border: 1.6px solid $color-black;
        border-radius: 10rem;
        font-size: 1.6rem;
        font-family: inherit;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: .2rem;
        background-color: transparent;
        cursor: pointer;
        transition: transform .2s ease-in-out;

        i {
            margin-left: 1rem;
        }
    }

    &:hover {
        transform: scaleX(1.1) scaleY(1.1);

        &::after {
            animation: slidebg 4s linear infinite;
            opacity: 0.8;
        }
    }

    &:active {
        transform: translateY(-2px);
    }

    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 10rem;
        background-image: linear-gradient(86.78deg, #7B6D8D -5.26%, #A5C4D4 13.74%, #FFBF69 32.51%, #FFA5AB 50.71%, #FFBF69 67.77%, #A5C4D4 86.53%, #7B6D8D 103.92%);
        filter: blur(1.8rem);
        overflow: hidden;
        opacity: 0;
        transition: all .2s ease-in-out;
    }
}

.header-hero {
    padding: 6rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hero-content {
    h2 {
        font-size: 2.2rem;
        font-weight: 800;
    }

    h4 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    &.mobile-only {
        display: none;
    }
}

.hero-art {
    width: 10vw;
    position: relative;

    video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media only screen and (max-width: 1059px) {
    .hero-content.mobile-only {
        display: block;
    }

    .hero-content.default, .hero-buttons {
        display: none;
    }

    .hero-art {
        width: 20vw;
    }
}

.bg-art {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    height: 100vh;
    overflow: hidden;
    z-index: -10;
    opacity: 0.2;
    filter: blur(3.2rem);
    -webkit-mask-image: linear-gradient(
        to right, /* gradient direction */
        rgba(0,0,0,0) 0%, /* transparent left 3% */
        rgba(0,0,0,1) 3%, /* visible in the middle start */
        rgba(0,0,0,1) 97%, /* visible in the middle end */ 
        rgba(0,0,0,0) 100% /* transparent right 3% */ 
    );

    video {
        width: 100%;
        height: auto;
        position: absolute;
        top: -20rem;
    }
}

.content {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
    justify-items: center;
}

.tabs {
    margin-top: 10rem;

    &-header {
        display: flex;
        width: 60vh;

        @media only screen and (max-width: 519px) {
            & {
                width: 30vh;
            }
        }

        &-item {
            font-size: 1.6rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: .2rem;
            padding: 1.2rem 4rem;
            width: calc(60vh / 2);
            text-align: center;
            cursor: pointer;
            position: relative;
            transition: transform .2s ease-in-out;

            @media only screen and (max-width: 519px) {
                & {
                    width: calc(30vh / 2);
                }
            }

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                border-radius: 10rem;
                background-image: linear-gradient(86.78deg, #7B6D8D -5.26%, #A5C4D4 13.74%, #FFBF69 32.51%, #FFA5AB 50.71%, #FFBF69 67.77%, #A5C4D4 86.53%, #7B6D8D 103.92%);
                filter: blur(2rem);
                overflow: hidden;
                opacity: 0;
                transition: all .2s ease-in-out;
            }

            &:hover {
                &::after {
                    animation: slidebg2 4s linear infinite;
                    opacity: 0.6;
                }
            }

            &:active {
                transform: scaleX(0.9) scaleY(0.9);
            }
        }
    }

    &-indicator {
        position: relative;
        width: calc(60vh / 2);
        height: 1.6px;
        background-color: $color-black;
        left: 0;
        transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
        
        &.point-to-works {
            left: calc(calc(60vh / 2) * 0);
        }

        &.point-to-about {
            left: calc(calc(60vh / 2) * 1);
        }

        @media only screen and (max-width: 519px) {
            & {
                width: calc(30vh / 2);
            }

            &.point-to-works {
                left: calc(calc(30vh / 2) * 0);
            }
    
            &.point-to-about {
                left: calc(calc(30vh / 2) * 1);
            }
        }
    }
}

.content-works {
    padding: 6rem 0;
    animation: contentSlideUp .2s ease-in;
    width: 100%;
    
    &-title {
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: .4rem;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 2rem;
        margin-left: 2rem;
    }

    &-list {
        width: 100%;
        height: min-content;
        display: grid;
        // grid-template-columns: repeat(3, calc(100% / 3));
        grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));

        @media only screen and (max-width: 759px) {
            & {
                grid-template-columns: repeat(auto-fit, minmax(120px, 100%));
            }
        }

        .work {
            padding: 2rem;
            
            &-card {
                border: 1.6px solid $color-black;
                border-radius: 2rem;
                overflow: hidden;
                width: 100%;
                height: max-content;
                position: relative;
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {
                    transform: scaleX(1.04) scaleY(1.04);

                    .work-card-img img {
                        filter: grayscale(10%);
                    }
                }

                &-img img {
                    width: 100%;
                    filter: grayscale(60%);
                    -webkit-mask-image: -webkit-gradient(linear, left 20%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
                }

                h2 {
                    font-size: 2.2rem;
                    font-weight: 600;
                    position: absolute;
                    left: 2rem;
                    bottom: 2rem;
                }
            }
        }

        @media only screen and (max-width: 1489px) {
            .work:last-of-type {
                transform: translateX(50%);
            }
        }

        @media only screen and (max-width: 1059px) {
            .work:last-of-type {
                transform: translateX(0);
            }
        }
    }
}

.modal {
    &.open {
        display: block;
    }

    &.animate-fadeOut {
        animation: modalHide .2s ease-out;
    }

    &-bg {
        animation: modalDisplay .2s ease-in;
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @supports not (backdrop-filter: blur(4rem)) {
            background: $color-modal-bg;
        }

        @supports (backdrop-filter: blur(4rem)) {
            background: $color-modal-bg;
            backdrop-filter: blur(0.6rem);
        }
    }

    &-close {
        position: absolute;
        top: 4rem;
        right: 3.4rem;
        cursor: pointer;
        border: none;
        background: transparent;
        text-decoration: none;
        // padding: 2rem;
        font-size: 2.4rem;
        font-weight: 400;
        opacity: 0.6;
        transition: all .2s ease-in-out;

        &:hover {
            transform: scaleX(1.2) scaleY(1.2);
        }
    }

    &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 2rem 0;
        margin-bottom: 2rem;
        align-items: center;

        h2 {
            font-size: 2.6rem;
            font-weight: 400;
        }
    }

    &-content {
        animation: modalSlideUp .2s ease-in;
        position: absolute;
        top: 6rem;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 2rem 2rem 0 0;
        z-index: 10;
        border: 1.6px solid $color-black;
        border-bottom: none;
        background-color: $color-bg;
        padding: 4rem;
        width: 80%;
        height: -webkit-fill-available;
        display: grid;
        grid-template-rows: auto 1fr;
        overflow: hidden;

        @media only screen and (max-width: 419px) {
            padding: 2rem;
        }
    }
}

.header-art-bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-mask-image: -webkit-gradient(linear, left 40%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #19161d15;
    border-radius: 10rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #19161Db0;
    border-radius: 10rem;
    z-index: 20;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #19161Dcb;
}

.content-about {
    // width: 100%;
    height: min-content;
    padding: 4rem;
    border-radius: 2rem 2rem 0 0;
    border: 1.6px solid $color-black;
    border-bottom: none;
    margin-top: 6rem;
    font-size: 1.6rem;
    animation: contentSlideUp .2s ease-in;

    @media only screen and (max-width: 739px) {
        padding: 2rem;
    }

    &-section {
        margin: 6rem 0;
        padding: 2rem;

        h3 {
            font-size: 2.6rem;
            font-weight: 600;
            letter-spacing: .6rem;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 2rem;

            &::after {
                content: "";
                position: absolute;
                border: 1.6px solid $color-black;
                width: 10rem;
                margin-left: 2rem;
                top: 50%;
                transform: translateY(-50%);

                @media only screen and (max-width: 439px) {
                    visibility: hidden;
                }
            }
        }
    }

    &-subhero {
        &-section {
            margin: 0;
        }

        &-img {
            text-align: right;

            img {
                width: 80%;
            }

            @media only screen and (max-width: 739px) {
                text-align: center;
                margin: 4rem 0;

                img {
                    width: 60%;
                }
            }
        }

        &-section {
            display: grid;
            grid-template-columns: 1fr 30%;
            align-items: center;

            @media only screen and (max-width: 739px) {
                grid-template-columns: 1fr;
            }
        }

        &-texts {
            h1 {
                font-size: 4rem;
                font-weight: 800;
                margin-bottom: 2.8rem;
            }

            h2 {
                font-size: 2rem;
                font-weight: 400;
            }
        }
    }

    &-more {
        &-texts {
            display: flex;
            align-items: flex-start;
        }

        &-text:not(:last-of-type) {
            margin-right: 2rem;
        }
    }

    &-skills {
        &-content {
            display: grid;
            grid-template-columns: 1fr 30%;
            align-items: center;

            @media only screen and (max-width: 739px) {
                grid-template-columns: 1fr;
            }
        }

        &-text:not(:last-of-type) {
            margin-bottom: 2rem;
        }
    }

    &-connect {
        h1 {
            font-size: 4rem;
            font-weight: 800;
            margin-bottom: 2rem;
        }

        &-buttons {
            margin-top: 2rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @media only screen and (max-width: 1179px) {
                grid-template-columns: 1fr;
            }

            // width: 100%;

            .connect-btn {
                text-align: center;
                text-decoration: none;
                color: $color-black;
                margin: 2rem;
                border: 1.6px $color-black solid;
                // width: 100%;
                
                // display: flex;
                // align-items: center;
                // justify-content: center;

                @media only screen and (min-width: 1180px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                padding: 1.2rem 0;
                border-radius: 2rem;
                transition: transform .2s ease-in-out;

                &-details {
                    @media only screen and (max-width: 1179px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 4rem;
                    }

                    @media only screen and (max-width: 459px) {
                        flex-direction: column;
                    }

                    &-title {
                        font-size: 1.6rem;
                        font-weight: 600;
                        letter-spacing: .4rem;
                        text-transform: uppercase;
                    }

                    &-handle {
                        font-size: 1.2rem;
                        font-style: italic;
                    }
                }

                i {
                    margin-right: 2rem;
                }

                &:hover {
                    transform: scaleX(1.1) scaleY(1.1);
            
                    &::after {
                        animation: slidebg 4s linear infinite;
                        opacity: 0.8;
                    }
                }
            
                &:active {
                    transform: translateY(-2px);
                }
            
                &::after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    border-radius: 10rem;
                    background-image: linear-gradient(86.78deg, #7B6D8D -5.26%, #A5C4D4 13.74%, #FFBF69 32.51%, #FFA5AB 50.71%, #FFBF69 67.77%, #A5C4D4 86.53%, #7B6D8D 103.92%);
                    filter: blur(1.8rem);
                    overflow: hidden;
                    opacity: 0;
                    transition: all .2s ease-in-out;
                }
            }
        }
    }
}