$color-black: #19161D;

.work-content {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    margin-top: 6rem;
    -webkit-mask-image: linear-gradient(
        to top, /* gradient direction */
        rgba(0,0,0,0) 0%, /* transparent left 3% */
        rgba(0,0,0,1) 3%, /* visible in the middle start */
        rgba(0,0,0,1) 97%, /* visible in the middle end */ 
        rgba(0,0,0,0) 100% /* transparent right 3% */ 
    );

    p, ul > li {
        font-size: 1.6rem;
        color: $color-black;
    }

    .work-title-section {
        margin-top: 2rem;

        h1 {
            font-size: 4rem;
            font-weight: 800;
        }

        h2 {
            font-size: 2.2rem;
            font-weight: 600;
        }

        &.work-1 {
            font-family: 'Montserrat', sans-serif;

            h1 {
                color: #011638;
            }
    
            h2 {
                color: #0D21A1;
            }
        }

        &.work-2 {
            font-family: 'Suez One', serif;

            h1 {
                color: #32292F;
            }
    
            h2 {
                color: #5762D5;
            }
        }
    }

    &.work-1 {
        section h2 {
            font-family: 'Montserrat', sans-serif;
        }
    }

    &.work-2 {
        section h2 {
            font-family: 'Suez One', serif;
        }
    }

    section {
        margin-top: 4rem;
        
        h2 {
            font-family: 'Montserrat', sans-serif;
            font-size: 2rem;
            font-weight: 800;
        }
    }

    .work-section {
        padding: 2rem;

        ul {
            padding-left: 2rem;
        }

        &-col-2 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 859px) {
                flex-direction: column-reverse;
            }

            .work-part-right img {
                height: 44vh;

                @media only screen and (max-width: 859px) {
                    height: 20vh;
                }
            }
        }
    }

    .work-part {
        // &-col-2 {
        //     display: grid;
        //     grid-template-columns: 1fr 50%;

        //     .work-part-right img {
        //         width: 100%;
        //     }
        // }
        &-col-2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @media only screen and (max-width: 419px) {
                grid-template-columns: 1fr;
            }

            &.mt-20 {
                margin-top: 2rem;
            }

            &.mb-20 {
                margin-bottom: 2rem;
            }

            &.text-center {
                text-align: center;
            }

            .work-part-text {
                margin-bottom: 0;
                border-radius: 1rem;
                background: #00000018; //temporary bg
                padding: 2rem;

                @media only screen and (max-width: 419px) {
                    margin-bottom: 1rem;
                }

                ul {
                    padding-left: 2rem;
                }

                h4 {
                    font-size: 1.6rem;
                }
            }

            @media only screen and (min-width: 420px) {
                .work-part-text:not(:last-of-type) {
                    margin-right: 1rem;
                }
            }
            

            &.with-img {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 60%;
    
                    &.mr-20 {
                        margin-right: 2rem;
                    }
    
                    &.ml-20 {
                        margin-left: 2rem;
                    }
                }
            }

            
        }

        &-col-3 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @media only screen and (max-width: 549px) {
                grid-template-columns: 1fr;
            }
            
            &.mt-20 {
                margin-top: 2rem;
            }

            .work-part-text {
                margin-bottom: 0;
                border-radius: 1rem;
                background: #00000018; //temporary bg
                padding: 2rem;

                @media only screen and (max-width: 549px) {
                    margin-bottom: 1rem;
                }

                h4 {
                    font-size: 1.6rem;
                }
            }

            @media only screen and (min-width: 550px) {
                .work-part-text:not(:last-of-type) {
                    margin-right: 1rem;
                }
            }
        }

        &-fourths {
            margin: 2rem 0;
            display: grid;

            @media only screen and (min-width: 550px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
            }

            @media only screen and (max-width: 549px) {
                grid-template-rows: 1fr;
            }

            .work-part-text {
                border-radius: 1rem;
                margin-bottom: 1rem;
                padding: 2rem;
                background: #00000018; //temporary bg
            }

            @media only screen and (min-width: 550px) {
                .work-part-text:nth-child(3), .work-part-text:nth-child(4) {
                    margin-bottom: 0;
                }
    
                .work-part-text:nth-child(odd) {
                    margin-right: 1rem;
                }
            }

            @media only screen and (max-width: 549px) {
                margin-bottom: 1rem;
            }
        }

        &-text {
            margin-bottom: 4rem;

            h4 {
                font-size: 1.8rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            &.sub {
                background: #00000018; //temporary bg
                padding: 2rem;
                border-radius: 1rem;
                
                h4 {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .count {
        display: inline-block;
        width: 2rem;
        line-height: 2rem;
        text-align: center;
        background: $color-black;
        color: #ffffff;
        padding: .4rem;
        border-radius: 50%;
        font-size: 1.6rem;
        font-weight: 700;

        &.inline {
            margin-right: 1rem;
        }

        &.mb-20 {
            margin-bottom: 2rem;
        }
    }

    .work-center-img {
        text-align: center;
        margin: 1rem 0;

        &.w-40 img {
            width: 40%;
        }

        &.w-60 img {
            width: 60%;
        }

        &.w-80 img {
            width: 80%;
        }

        &.mb-20 img {
            margin-bottom: 2rem;
        }

        &.mt-20 img {
            margin-top: 2rem;
        }
    }

    .italic {
        font-style: italic;
    }

    .work-appreciation-msg-section {
        h2 {
            font-size: 2.2rem;
            font-weight: 800;
            margin-bottom: 2rem;
        }
    }
}